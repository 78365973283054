import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'light',
};
const styles = {
  global: {
    body: {
      // color: 'white',
    },
  },
};
const fonts = {
  body: 'Plus Jakarta Sans',
  heading: 'Plus Jakarta Sans',
};

export const chakraTheme = extendTheme({
  config,
  styles,
  fonts,
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors: {
    brand: {
      200: '#BE6F4F',
      default: '#BE6F4F',
      500: '#BE6F4F',
    },
    white: {
      200: '#ffffff',
      default: '#ffffff',
      500: '#ffffff',
    },
    text: {
      200: ' #053c34',
      default: ' #053c34',
      500: ' #053c34',
    },
  },
});
