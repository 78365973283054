import { ChakraProvider } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { chakraTheme } from "config/chakra";
export function openConfirmDialog({
  title,
  description,
  onCancel,
  onOk,
  cancelText,
  confirmText,
  confirmBtnProps = {},
  cancelBtnProps = {}
}) {
  const div = document.createElement("div");
  document.body.appendChild(div);

  function handleCleanup() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  ReactDOM.render(
    <ChakraProvider theme={chakraTheme}>
      <ConfirmAlertBox
        open={true}
        title={title}
        description={description}
        onCancel={onCancel}
        onOk={onOk}
        cancelText={cancelText}
        confirmText={confirmText}
        handleCleanup={handleCleanup}
        confirmBtnProps={confirmBtnProps}
        cancelBtnProps={cancelBtnProps}
      />
    </ChakraProvider>,
    div
  );
  return handleCleanup;
}

function ConfirmAlertBox({
  open,
  title,
  description,
  onCancel,
  onOk,
  cancelText,
  confirmText,
  handleCleanup,
  confirmBtnProps = {},
  cancelBtnProps = {}
}) {
  const cancelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(open);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onCancel}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                typeof onCancel === "function" && onCancel();
                setIsOpen(false);
                handleCleanup();
              }}
              size="md"
              {...cancelBtnProps}
            >
              {cancelText || "Cancel"}
            </Button>
            &nbsp;&nbsp; 
            <Button
              colorScheme="red"
              onClick={() => {
                typeof onOk === "function" && onOk();
                setIsOpen(false);
                handleCleanup();
              }}
              size="md"
              {...confirmBtnProps}
            >
              {confirmText || "Confirm"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
