import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from 'react';
import AccountBlocked from 'components/AccountBlocked';
import { logout } from 'utils';

export const BannedStatusContext = createContext({
  bannedModal: false,
  setBannedModal: () => {},
});
export function useBannedStatus() {
  // check if bannedstatuscontext is null
  const context = useContext(BannedStatusContext);
  if (context === null) {
    return null;
  }
  return context;
}

export default function BannedStatusProvider({ children }) {
  const [bannedModal, setBannedModal] = useState(false);
  const value = {
    bannedModal,
    setBannedModal,
  };
  function forceLogout() {
    logout();
    window.open('/', '_self');
  }

  useEffect(() => {
    if (bannedModal) {
      setTimeout(() => {
        forceLogout();
      }, 10000);
    }
  }, [bannedModal]);
  return (
    <BannedStatusContext.Provider value={value}>
      <>
        {children}
        {bannedModal && <AccountBlocked isOpen={true} onClose={forceLogout} />}
      </>
    </BannedStatusContext.Provider>
  );
}
