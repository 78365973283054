import { useRef, useEffect } from 'react';

export function useInterval(callback, timeout = 200, deps = [], cleanupFn) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    let id = setInterval(tick, timeout);
    function tick() {
      savedCallback.current(() => {
        clearInterval(id);
      });
    }

    return () => {
      cleanupFn && cleanupFn();
      clearInterval(id);
    };
  }, deps);
}
