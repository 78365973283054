import { openConfirmDialog } from 'components/Alerts';
import { useInterval } from 'hooks/useInterval';
import React, { useEffect, useRef } from 'react';
import { StorageService } from 'service/Storage';
const LAST_REFRESH_TIME = 'last-refresh-time';

export default function ReloadApp({ duration = 86400 }) {
  const lastRefreshTime = StorageService.get(LAST_REFRESH_TIME, true);
  const isConfirmationOpen = useRef(false);

  useEffect(() => {
    if (!lastRefreshTime) {
      StorageService.set(LAST_REFRESH_TIME, Date.now() + duration * 1000, true);
    }
    reloadPage();
  }, []);

  useInterval(
    () => {
      reloadPage();
    },
    1000 * 30,
    []
  );

  function reloadPage() {
    const lastRefreshTime = StorageService.get(LAST_REFRESH_TIME, true);
    const shouldRefresh = Date.now() > lastRefreshTime;
    if (shouldRefresh && !isConfirmationOpen.current) {
      isConfirmationOpen.current = true;
      openConfirmDialog({
        title: 'Looks like the session has Expired!',
        description: 'Select refesh to see the latest changes',
        confirmText: 'Refresh',
        cancelText: 'Cancel',
        onOk: () => {
          window.location.reload();
          StorageService.set(
            LAST_REFRESH_TIME,
            Date.now() + duration * 1000,
            true
          );
          isConfirmationOpen.current = false;
        },
        onCancel: () => {
          isConfirmationOpen.current = false;
        },
      });
    }
  }

  return null;
}
